import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import logo from '../img/logo.webp';
import { useTranslation } from 'react-i18next';

export default function Bottom() {
    const { t } = useTranslation();
    return (
        <Container fluid>
            <div class='row bg-my py-3'>
                <div class='col-lg-3 col-md-5 col-sm-10 m-auto text-center'>
                    <img src={logo} height="80" width="80" className="d-inline-block align-center" alt="Logo" />
                    <h5 class='py-3 '>Geosport Group SRL</h5>
                </div>
                <div class='d-none d-lg-block col-lg-4 col-xl-3 d-lg-block d-xl-block mblink m-auto'>
                    <Nav.Link as={Link} to="/InstalareIarba"><u>{t('menu.works2')}</u></Nav.Link>
                    <Nav.Link as={Link} to="/InstalareTartan"><u>{t('menu.works3')}</u></Nav.Link>
                    <Nav.Link as={Link} to="/Proiectare"><u>{t('menu.works4')}</u></Nav.Link>
                    <Nav.Link as={Link} to="/Echipament"><u>{t('menu.works5')}</u></Nav.Link>
                </div>
                <div class='col-lg-5 col-md-10 col-sm-10 mblink m-auto text-center'>
                    <p class='font-size: larger'><b>{t('menu.works6')}:</b></p>
                    <div className='row'>
                        <div class='col-lg-6 col-md-6 col-sm-6 col-6 mblink m-auto text-end'>
                            <p class=''>{t('home.contactsMoldova')}</p>
                            <p class=''>{t('home.contactsRomania')}</p>
                            <p class=''>{t('home.contactsUkraine')}</p>
                            </div>
                            <div class='col-lg-5 col-md-6 col-sm-6 col-6 mblink m-auto text-start'>
                            <p>(+373) 787 53 333</p>
                            <p>(+373) 787 53 333</p>
                            <p>(+373) 787 53 333</p>
                            
                        </div>
                    </div>
                </div>
            </div>
            {/* <a href="mailto:info@geosport.md">info@geosport.md</a> */}
        </Container>
    );
}

