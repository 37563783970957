import React from 'react';
import Container from 'react-bootstrap/Container';
import img2 from '../img/2.webp';
import img3 from '../img/3.webp';
import { useTranslation } from 'react-i18next';

export default function Home2() {
    const { t } = useTranslation();
        return (
            <Container>
                <div class='row  mbg pt-5'>
                    <div class='col-lg-10 col-md-12'>
                        <h1 class='text-center py-3 '>{t('home.a1')}</h1>
                        <p>{t('home.a2')}</p>
                    </div>
                </div>
                <div class='row  mt-4 mbg '>
                    <div class=' col-lg-5 col-md-6  '>
                        <hr />
                        <p>{t('home.a3')}</p>
                        <hr />
                    </div>
                    <div class=' col-lg-5 col-md-6 col-sm-10  '>
                        <img
                            width={540}
                            height={198}
                            src={img2}
                            class="par1 img-fluid"
                            alt="Ball"
                        />
                    </div>
                </div>
                <div class='row  mbg pb-5 flex-column-reverse flex-md-row'>
                    <div class='col-lg-5 col-md-6 col-sm-10'>
                        <img
                            width={540}
                            height={198}
                            src={img3}
                            class="par1 img-fluid"
                            alt="Paper"
                        />
                    </div>
                    <div class='col-lg-5 col-md-6'>
                        <hr />
                        <p>{t('home.a4')}</p>
                        <hr />
                    </div>

                </div>
            </Container>
        );
    }
