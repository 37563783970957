import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import img1 from '../img/4.webp';
import img2 from '../img/5.webp';
import img3 from '../img/6.webp';
import img4 from '../img/7.webp';
import { useTranslation } from 'react-i18next';

export default function Home3() {
    const { t, } = useTranslation();
    return (
        <Container>
            <hr />
            <div class='row  mbg pt-5 d-flex '>
                <div class='col-lg-5 col-md-6 col-sm-10 d-flex justify-content: center'>
                    <img width={550} height={412} src={img1} class="par1 img-fluid" alt="grass-on-field" />
                </div>
                <div class='col-lg-5 col-md-6'>
                    <h3 class=' py-3 text-start'>{t('home.b1')}</h3>
                    <p class='text-start'>{t('home.b2')}</p>
                    <p class='text-start'>{t('home.b3')}</p>
                    <p class='text-start'>{t('home.b4')}</p>
                    <div class='text-center'>
                        <Button as={Link} to="/InstalareIarba" className="mbtn" size="lg">
                            {t('home.details')}
                        </Button>
                    </div>
                </div>
            </div>

            <hr />
            <div class='row  mbg pt-5  flex-column-reverse flex-md-row'>
                <div class='col-lg-5 col-md-6'>
                    <h3 class=' py-3 text-start'>{t('home.b5')}</h3>
                    <p class='text-start'>{t('home.b6')}</p>
                    <p class='text-start'>{t('home.b7')}</p>
                    <p class='text-start'>{t('home.b8')}</p>
                    <div class='text-center'>
                        <Button as={Link} to="/InstalareTartan" className="mbtn" size="lg">
                            {t('home.details')}
                        </Button>
                    </div>
                </div>
                <div class='col-lg-5 col-md-6 col-sm-10 justify-content: center'>
                    <img width={550} height={412} src={img2} class="par1 img-fluid" alt="tartan" />
                </div>
            </div>

            <hr />
            <div class='row  mbg pt-5 d-flex '>
                <div class='col-lg-5 col-md-6 col-sm-10 d-flex justify-content: center'>
                    <img width={550} height={412} src={img3} class="par1 img-fluid" alt="proiect" />
                </div>
                <div class='col-lg-5 col-md-6'>
                    <h3 class=' py-3 text-start'>{t('home.b9')}</h3>
                    <p class='text-start'>{t('home.b10')}</p>
                    <p class='text-start'>{t('home.b11')}</p>
                    <p class='text-start'>{t('home.b12')}</p>
                    <div class='text-center'>
                        <Button as={Link} to="/Proiectare" className="mbtn" size="lg">
                            {t('home.details')}
                        </Button>
                    </div>
                </div>
            </div>

            <hr />
            <div class='row  mbg py-5  flex-column-reverse flex-md-row'>
                <div class='col-lg-5 col-md-6'>
                    <h3 class=' py-3 text-start'>{t('home.b13')}</h3>
                    <p class='text-start'>{t('home.b14')}</p>
                    <p class='text-start'>{t('home.b15')}</p>
                    <p class='text-start'>{t('home.b16')}</p>
                    <p class='text-start'>{t('home.b17')}</p>
                    <div class='text-center'>
                        <Button as={Link} to="/Echipament" className="mbtn" size="lg">
                            {t('home.details')}
                        </Button>
                    </div>
                </div>
                <div class='col-lg-5 col-md-6 col-sm-10 justify-content: center'>
                    <img width={550} height={412} src={img4} class="par1 img-fluid" alt="baschet-plate" />
                </div>
            </div>
        </Container>
    );
}
