import React, { Component } from 'react';
import InstalareIarba1 from '../Components/InstalareIarba1';
import InstalareIarba2 from '../Components/InstalareIarba2';
import InstalareIarbaExemple from '../Components/InstalareIarbaExemple';
import Bottom from '../Components/Bottom';
import GoToTop from '../Components/GoToTop';
import ScrollToTop from "react-scroll-to-top";

class InstalareIarba extends Component {
    render() {
        return (
            <>
                <InstalareIarba1 />
                <InstalareIarba2 />
                <InstalareIarbaExemple />
                <Bottom />
                <GoToTop />
                <ScrollToTop />
            </>
        );
    }
}

export default InstalareIarba;