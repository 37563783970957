import React, { Component } from 'react';
import GoToTop from '../Components/GoToTop';
import Echipament1 from '../Components/Echipament1';
import Bottom from '../Components/Bottom';
import ScrollToTop from "react-scroll-to-top";

class Echipament extends Component {
    render() {
        return (
            <div>
                <Echipament1 />
                <Bottom />
                <GoToTop />
                <ScrollToTop />
            </div>
        );
    }
}

export default Echipament;